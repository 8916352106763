//node modules
import {lazy} from "react";


//error-pages
import Error from "../pages/error/Error";


//pages
import Landing from "../pages/landing";
//import Campaign from "../pages/campaign";


//lazy-pages
//const Landing = lazy(() => import("../pages/landing"));
const Campaign = lazy(() => import("../pages/campaign"));
const Preview = lazy(() => import("../pages/preview"));
const CampaignQR = lazy(() => import("../pages/campaign/pages/qrcode"));
const Scratch = lazy(() => import("../pages/result/pages/scratch"));
const Result = lazy(() => import("../pages/result/pages/result"));
const About = lazy(() => import("../pages/about"));
const Order = lazy(() => import("../pages/checkout"));
const SignIn = lazy(() => import("../pages/development/LoginUser"));
const SignOut = lazy(() => import("../pages/development/SignOutUser"));
const AcceptTransaction = lazy(() => import("../pages/checkout/pages/redirect/AcceptTransaction"));
const DemoPayment  = lazy(() => import("../pages/checkout/pages/demoPayment/DemoPayment"));

//lazy-agreementPages
const Agreement = lazy(() => import("../pages/agreement"));


export const staticPages = [
    {
        link: "/",
        element: Landing,
    },
    {
        link: "/about",
        element: About,
    },
    {
        link: "/checkout",
        element: Order,
    },
    {
        link: "/checkout/accept/",
        element: AcceptTransaction,
    }
];

export const developmentPages = process.env.REACT_APP_ENV === "development" ? [
    {
        link: "/dev-signin",
        element: SignIn
    },
    {
        link: "/dev-signout",
        element: SignOut
    }
] : [];

export const dynamicPages = [
    {
        link: "/payment/:order",
        element: DemoPayment,
    },
    {
        link: "/preview/:campaign",
        element: Preview,
    },
    {
        link: "/:campaignRef",
        element: Campaign,
    },
    {
        link: "/:campaignRef/:qr",
        element: CampaignQR,
    },
    {
        link: "/scratch/:order",
        element: Scratch,
    },
    {
        link: "/result/:order",
        element: Result,
    },
];

export const agreementPages = [
    {
        link: "/agreement",
        element: Agreement,
    }
]

export const errorPages = [
    {
        link: "/not_found",
        element: Error,
    },
    {
        link: "/error",
        element: Error,
    },
    {
        link: "*",
        element: Error,
    },
];

export const pageTree = [
    //error pages
    ...errorPages,

    //pages
    ...staticPages,
    ...dynamicPages,
    ...developmentPages,

    //agreement pages
    ...agreementPages,
];
