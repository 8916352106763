//import React components
import {useEffect} from "react";
import {useLocation} from "react-router-dom";



//import components
import PageContainer from "./components/PageContainer";
import Navigation from "../modules/Navigation/Navigation";
import Footer from "../modules/Footer/Footer";
//import ScrollToTop from "./components/ScrollToTop";
import {scroller} from "react-scroll";


//callable functions


//font awesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas);

export default function PageLayout({...props}) {
    const location = useLocation();

    useEffect(() => {
        if (location.hash?.length && location.hash.length > 1) {
            const scrollLocation = location.hash.substring(1, location.hash.length);
            scroller.scrollTo(scrollLocation, {
                duration: 0,
                delay: 0,
                smooth: false,
                offset: scrollLocation === "termsOfPurchase" ? -230 : -100,
            })
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [location]);


    return (
        <>
            <Navigation/>
            <PageContainer>
                {props.children}
            </PageContainer>
            <Footer/>
            {/*<ScrollToTop/>*/}
        </>
    )
}
