//node modules
import React, {Suspense} from 'react';
import {pageTree} from "./context/pageTree";
import PageLayout from "./layouts/pageLayout";
import {Route, Routes} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import momentTz from "moment-timezone";
import moment from 'moment'
import 'moment/locale/nb'
moment.locale("nb");
momentTz.tz("Europe/Oslo");

export default function App() {
    return (
        <>
            <HelmetProvider>
                <PageLayout pageLinks={pageTree}>
                    <Suspense>
                        <Routes>
                            {pageTree.map((page, i) => {
                                return <Route path={page.link} element={<page.element/>} key={"page-" + i}/>;
                            })}
                        </Routes>
                    </Suspense>
                </PageLayout>
            </HelmetProvider>
        </>
    );
}
