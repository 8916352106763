import React from "react";

type props = {
    children: React.ReactNode | React.ReactNode[],
}

export default function PageContainer({...props}:props) {
    return (
        <>
            <div className="page" style={{display: "flex", flexDirection: "column", flexGrow: 1, height: "100%", }}>
                {props.children}
            </div>
        </>
    )
}
