//import Components
import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import {CampaignProps} from "../global/schemas/data/campaigns/campaign";
import {newOrderProps} from "../global/schemas/data/orders/order";

//----------------[This is code for global variables across all pages]---------------------//

type appDataTypes = {
    campaign?: CampaignProps,
    order?: newOrderProps,
}

export type appDataInterface = {
    appData: appDataTypes,
    setAppData: Dispatch<SetStateAction<appDataTypes | undefined>>,
};

const AppContext = createContext<appDataInterface | undefined>(undefined);


export function AppDataProvider({...props}) {
    const [appData, setAppData] = useState<appDataTypes>({} as appDataTypes);


    return (
        <>
            <AppContext.Provider value={{appData, setAppData} as appDataInterface}>
                {props.children}
            </AppContext.Provider>
        </>
    )
}

export function useAppData() {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useAppState must be within AppStateProvider");
    }
    return context;
}
