//node modules
import {useEffect, useState} from "react";


//global variables
import {staticPages} from "../../context/pageTree";


//hooks
import {useAppData} from "../../context/data";
import {useLocation} from "react-router-dom";

//components
import {Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll"

//media
import tuttiLogo from "../../media/images/logo.png";

export default function Footer() {
    const location = useLocation();
    const {appData} = useAppData();
    const [showSeller, setShowSeller] = useState(false);

    useEffect(() => {
        if (location.pathname !== "/checkout") {
            setShowSeller(!staticPages.find((p) => {
                return p.link === location.pathname
            }));
        }
    }, [location.pathname]);


    return (
        <>
            <footer className={"footer"}>
                <div className={`footer-top`}>
                    <img className={"footer-logo"} src={tuttiLogo} alt="Tuttilodd logo"/>
                    <p>
                        <span className={"br"}>
                            Dugnad.org en tjeneste levert av <a href="https://www.styreportalen.no">Styreportalen AS</a>
                        </span>
                        <span className={"br"}>
                            Styreportalen AS - Edvard Griegs vei 3B 5059 Bergen
                        </span>
                    </p>
                    <p>
                        {appData?.campaign && showSeller ? (
                            <>Selger er {appData?.campaign?.managerName}</>
                        ) : (
                            <>&nbsp;</>
                        )}
                    </p>
                </div>
                <div className={`footer-bottom`}>
                    <Row className={"footer-links"}>
                        <Link to={"/about"}>Om Innsamling</Link>
                    </Row>
                    <Row className={"footer-agreements"} sm={6}>
                        {location.pathname === "/agreement" ? (
                            <>
                                <ScrollLink className={"link"} to={"termsOfPurchase"} offset={-230}>Kjøpsvilkår & Personvernerklæring</ScrollLink>
                            </>
                        ) : (
                            <>
                                <Link to={"/agreement"}>Kjøpsvilkår & Personvernerklæring</Link>
                            </>
                        )}
                    </Row>
                </div>
            </footer>
        </>
    )
}
