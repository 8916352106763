//importing style
import 'react-phone-input-2/lib/style.css';
import "./styles/styles.scss";

//importing react components
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './tests/reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

//importing context providers
import {AppDataProvider} from "./context/data";
import {AppStateProvider} from "./context/state";


//importing components
import App from "./App";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      {/*Translation provider for website translations trough i18next*/}
      <I18nextProvider i18n={i18next}>
          {/*AppStateProvider is a contextProvider to provide a global state available across all pages*/}
          <AppStateProvider>
              {/*AppDataProvider is a contextProvider to provide a global data available across all pages*/}
              <AppDataProvider>
                  {/*Router container for website redirects*/}
                  <BrowserRouter>
                      {/*Application render*/}
                      <App/>
                  </BrowserRouter>
              </AppDataProvider>
          </AppStateProvider>
      </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
