//node modules
import {Link, useLocation} from "react-router-dom";


//global context
import {useAppState} from "../../context/state";


//components
import {Element as ScrollElement} from "react-scroll";


//images
import navLogo from "../../media/images/logo.png";

export default function Navigation() {
    const {appState} = useAppState();

    const location = useLocation();

    const urlRef = appState?.url || localStorage.getItem("urlRef") || false;
    const logoLink = (urlRef && (urlRef !== location.pathname || location.pathname !== "/")) ? urlRef : "/";

    return (
        <>
            <nav className={`nav`}>
                <ScrollElement name={"navigation"} className={"nav_container"}>
                    <div className={`nav-left`}>
                        <Link className={"nav-logo"}
                              to={logoLink}>
                            <img src={navLogo} alt="tuttilodd logo"/>
                        </Link>
                    </div>
                    <div className={`nav-right`}>
                        <ul className={"nav-links"}>
                            <Link className={"nav-link"} to={"/about"}>Om dugnad.org</Link>
                        </ul>
                    </div>
                </ScrollElement>
            </nav>
        </>
    )
}
