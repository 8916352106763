//import Components
import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";

//----------------[This is code for global variables across all pages]---------------------//

export type appStateTypes = {
    url?: string,
    orderRef?: string,
    campaignID?: string,
    sessionToken?: string,
    purchaser?: {
        name?: string,
        email?: string,
    }
}

export type appContextInterface =  {
    appState: appStateTypes | undefined,
    setAppState:  Dispatch<SetStateAction<appStateTypes | undefined>>
} | undefined;

const AppContext = createContext<appContextInterface>(undefined);


export function AppStateProvider({...props}) {
    const [appState, setAppState] = useState<appStateTypes>();

    return (
        <>
            <AppContext.Provider value={{appState, setAppState}}>
                {props.children}
            </AppContext.Provider>
        </>
    )
}

export function useAppState() {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useAppState must be within AppStateProvider");
    }
    return context;
}
