//node modules


//firebase


//components


//validators

import {Helmet} from "react-helmet-async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "reactstrap";

export default function LandingPage() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Innsamling, Salg av digitale skrapelodd for innsamling til organisajoner." key="d-description"/>
                <meta property="og:title" content="Innsamling" key="og-title"/>
                <meta property="og:description"
                      content="Innsamling, Salg av digitale skrapelodd for innsamling til organisajoner."
                      key="og-description"/>
                <meta property="og:image" content="./media/pageImage.jpg" key="og-image"/>

                <meta name="twitter:title" content="Innsamling" key="tw-title"/>
                <meta name="twitter:description"
                      content="Innsamling, Salg av digitale skrapelodd for innsamling til organisajoner."
                      key="tw-description"/>
                <meta name="twitter:image" content="./media/pageImage.jpg" key="tw-image"/>
            </Helmet>
            <div className={"landing"}>
                <div className="landing_content">
                        <div className="heading">
                            <h1>Digital Dugnad</h1>
                            <p>Dette er kanskje den enkleste dugnaden dere kan arrangere.</p>
                        </div>
                        <Row className="features">
                            <Col sm={4} className={"feature-item"}>
                                <div className={"icon"}>
                                    <FontAwesomeIcon icon={["fas", "money-bill-wave"]} />
                                </div>
                                <h3>Gratis å sette opp</h3>
                                <div>
                                    Det er gratis å sette opp en innsamlingskampanje.
                                    Vi håndterer betaling, trekning, lodd, samt gevinster og utsending av disse.
                                </div>
                            </Col>
                            <Col sm={4} className={"feature-item"}>
                                <div className={"icon"}>
                                    <FontAwesomeIcon icon={["fas", "hourglass-start"]} />
                                </div>
                                <h3>Enkelt å komme i gang</h3>
                                <div>
                                    I styreportalen kan man enkelt sette opp en innsamlingskampanje.
                                    Gå til moduler og velg innsamling.
                                </div>
                            </Col>
                            <Col sm={4} className={"feature-item"}>
                                <div className={"icon"}>
                                    <FontAwesomeIcon icon={["fas", "truck"]} />
                                </div>
                                <h3>Gevinster levert på døra</h3>
                                <div>
                                    Styreportalen står for alle gevinstene og utsending av disse.
                                    Det er mange gevinster å velge i mellom og flere vil det bli.
                                </div>
                            </Col>
                        </Row>
                        <div className="landing_actions">
                            <div className={"readMore"}>
                                <strong className={"br"}>Les mer hos oss på styreportalen.no</strong>
                                <a className={"btn btn-secondary"}
                                   href="https://styreportalen.no/innsamling"
                                   target={"_blank"}
                                   rel={"noreferrer nofollow"}>
                                    Les mer her
                                </a>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}
