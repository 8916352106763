//import Components

import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type locationProps = {
    state?: {
        type: string,
        error: string,
        message?: string,
    },
}

export default function Error() {
    const {state} = useLocation() as locationProps;

    return (
        <>
            <div style={{margin: "auto", textAlign: "center"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{margin: "0 auto"}}>
                        <em style={{
                            border: "6px solid black",
                            borderRadius: "100%",
                            fontSize: "10rem",
                            width: "125px",
                            height: "125px",
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            position: "relative",
                        }}>
                            <FontAwesomeIcon
                                className={"errorPage_icon"}
                                icon={"exclamation"}
                                style={{
                                    fontSize: "5rem",
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            />
                        </em>
                    </div>
                    <div style={{marginTop: "1.5rem"}}>
                        {state ? (
                            <h1 className={"fs3"}>{state.message || `${state.error} venligst prøv igjen.`}</h1>
                        ) : (
                            <h1 className={"fs3"}>Siden du prøver å finne eksisterer ikke</h1>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
